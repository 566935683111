import gql from 'graphql-tag'

export default gql`
  query GetExchangeRates {
    getExchangeRates {
      CZK
      EUR
    }
  }
`
