import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import getConfig from 'next/config'

import SAVE_CURRENCY, { saveCurrencyQuery } from '@layouts/localSettings/saveCurrency.store'
import { GetExchangeRatesQuery } from '@gql/__generated__'
import { Currency } from '@gql/__generated__'
import { getCurrencyByLanguage } from '@service/Currency'
import { useTranslation } from '@src/i18n'
import { makeDualPrice } from '@helpers/price'

import QUERY from './useExchangeRate/getExchangeRatesQuery.api'

const { CURRENCY, RATES } = getConfig().publicRuntimeConfig

const useExchangeRate = (currency: Currency = null) => {
  const { i18n } = useTranslation()
  const { data: { savedCurrency = null } = {} } = useQuery<saveCurrencyQuery>(SAVE_CURRENCY, {
    fetchPolicy: 'cache-only',
  })
  const userCurrency = savedCurrency || getCurrencyByLanguage(i18n.languages[0])
  const { data: exchangeData } = useQuery<GetExchangeRatesQuery>(QUERY, {
    ssr: false,
    skip: userCurrency === Currency.Czk && !currency,
  })

  const exRates = get(exchangeData, 'getExchangeRates')
  if (!exRates) return [RATES[CURRENCY], CURRENCY]
  if (currency) return [exRates[currency], currency]
  return [exRates[userCurrency], userCurrency]
}

export default useExchangeRate

export const useDualPrice = (amount: number) => {
  const [exRate, currency] = useExchangeRate()
  return makeDualPrice(
    {
      amount: amount,
      currency: Currency.Czk,
    },
    exRate,
    currency,
  )
}
