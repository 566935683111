// tslint:disable-next-line:class-name
export interface saveCurrencyQuery {
  savedCurrency: string
}

import gql from 'graphql-tag'

export default gql`
  {
    savedCurrency @client
  }
`
