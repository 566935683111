import { ComponentProps, ReactNode } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'

import Box from '@base/Box'

type Props = ComponentProps<typeof Box> & {
  children: ReactNode
  className?: string
  variant?: 'white' | 'alabaster'
}

const Well = ({ className, children, p = 3, variant = 'white', ...props }: Props) => {
  const theme: Theme = useTheme()

  let colorProps

  // Custom colors has precedence over variants
  if (props.bgcolor) {
    colorProps = {
      bgcolor: props.bgcolor,
      // Use border when custom color is set to have same dimensions as component with variant (unless overridden by border prop)
      border: `1px solid ${props.bgcolor}`,
    }
  } else {
    // Predefined color variants
    if (variant === 'alabaster') {
      colorProps = {
        bgcolor: theme.palette.customBg.alabaster,
        border: `1px solid ${theme.palette.grey[200]}`,
      }
    } else {
      colorProps = {
        bgcolor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[200]}`,
      }
    }
  }

  return (
    <Box borderRadius={theme.shape.borderRadius} className={className} p={p} {...colorProps} {...props}>
      {children}
    </Box>
  )
}

export default Well
