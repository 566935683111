import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import logo from './logo/logo.png'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    width: 'auto',
  },
  logo: {
    height: 50,
    [breakpoints.up('md')]: {
      height: 80,
    },
  },
}))

const Logo = () => {
  const classes = useStyles({})

  return (
    <Grid container alignItems='center' className={classes.root}>
      <img src={logo} alt='Terap.io' className={classes.logo} />
    </Grid>
  )
}

export default Logo
